/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import shortid from "shortid"

import ChevronDownSvg from "../icons/chevron-down.svg"

const range = (start, end, length = end - start) =>
  Array.from({ length }, (_, i) => start + i)

const Pagination = ({ previousPage, nextPage, page, totalPages }) => {
  const [selectedPage, setSelectedPage] = useState(page)

  const goToPage = e => {
    const page = parseInt(e.target.value)
    setSelectedPage(page)
    navigate(page === 1 ? `/blog` : `/blog/p${page}`)
  }
  return (
    <>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link
          sx={{
            variant: previousPage ? "text.link" : "text.linkDisabled",
          }}
          to={previousPage ? previousPage : "/"}
        >
          Previous
        </Link>
        <Link
          sx={{
            variant: nextPage ? "text.link" : "text.linkDisabled",
          }}
          to={nextPage ? nextPage : "/"}
        >
          Next
        </Link>
      </div>
      <div
        sx={{
          mt: 0,
          display: "flex",
        }}
      >
        <span>{`Page `}</span>
        <div
          sx={{
            position: "relative",
          }}
        >
          <select
            value={selectedPage}
            onChange={goToPage}
            sx={{
              fontSize: 1,
              appearance: "none",
              mx: "0.2em",
              paddingLeft: "0.2em",
              paddingRight: 0,
              "&:focus": {
                outlineColor: "primary",
              },
            }}
          >
            {range(0, totalPages).map(i => (
              <option key={shortid.generate()} value={i + 1}>{`${i +
                1}`}</option>
            ))}
          </select>
          <ChevronDownSvg
            sx={{
              position: "absolute",
              right: "12px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        </div>

        <span>{`of ${totalPages}`}</span>
      </div>
    </>
  )
}

Pagination.defaultProps = {}

export default Pagination
