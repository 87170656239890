/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import MotionWrapper from "../components/motion-wrapper"
import Container from "../components/container"
import Slab from "../components/slab"
import BlogList from "../components/blog-list"
import Pagination from "../components/pagination"
import SEO from "../components/seo"

const BlogIndexPage = ({ data, pageContext }) => {
  const {
    posts,
    entry: [entry],
  } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      section: "Blog",
      inverseHeaderColor: true,
    })
  }, [updateHeaderState])

  const footerLinks = [
    {
      title: "View our work",
      slug: "/made",
    },
    {
      title: "Contact us",
      slug: "/contact",
    },
    {
      title: "Go back home",
      slug: "/",
    },
  ]

  return (
    <>
      <SEO
        description={entry.metaDescription}
        title={entry.metaTitle ? entry.metaTitle : entry.title}
        image={entry.metaImage[0].url}
      />
      <MotionWrapper footerLinks={footerLinks}>
        <Slab first slabSx={{ display: "flex", pb: 1 }}>
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "stretch",
            }}
          >
            <Container>
              <BlogList posts={posts} />
            </Container>
            <Container>
              <Pagination {...pageContext} />
            </Container>
          </div>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const BlogIndexPageQuery = graphql`
  query($limit: Int, $offset: Int) {
    craft {
      entry: entries(type: ["blogIndex"]) {
        ... on craft_blogIndex_blogIndex_Entry {
          metaTitle
          metaDescription
          metaImage {
            url
          }
        }
      }
      posts: entries(type: ["blogPost"], limit: $limit, offset: $offset) {
        ... on craft_blog_blogPost_Entry {
          typeHandle
          id
          title
          slug
          postDate
        }
      }
    }
  }
`

export default BlogIndexPage
