/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { Link } from "gatsby"
import shortid from "shortid"
import { motion } from "framer-motion"

import Heading from "./heading"
import Date from "./date"

import transition from "../utils/framer-transition"

const listVariants = {
  initial: { x: -60, opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: {
    x: -60,
    opacity: 0,
    transition: { ...transition },
  },
}

const BlogList = ({ borderColor, posts }) => {
  const Tag = transition ? motion.ul : `ul`
  return (
    <Tag
      variants={listVariants}
      sx={{
        listStyle: "none",
      }}
    >
      {posts.map(post => {
        console.log(post.author)
        return (
          <motion.li
            variants={listVariants}
            sx={{
              a: {
                paddingBottom: 1,
                marginBottom: 1,
                borderBottom: "1px solid",
                borderColor: borderColor,
                display: "block",
                textDecoration: "none",
              },
            }}
          >
            <Link
              variants={listVariants}
              to={`/blog/${post.slug}`}
              key={`blog-card-${post.slug}`}
              title={post.title}
            >
              <article>
                <Heading
                  hLevel={3}
                  noAnimate
                  fullOpacity
                  headingSx={{
                    marginBottom: 0,
                  }}
                >
                  {post.title}
                </Heading>
                <p
                  sx={{
                    marginBottom: "0px",
                    fontSize: 0,
                  }}
                >
                  <Date format="Do MMM YYYY" dateCreated={post.postDate} />
                </p>
              </article>
            </Link>
          </motion.li>
        )
      })}
    </Tag>
  )
}

BlogList.defaultProps = {
  borderColor: "muted",
}

export default BlogList
